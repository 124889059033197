// @flow
import React from 'react'

import { useHover } from '@edison/webmail-ui/hooks/hover'

import type { Node } from 'react'

type Props = {
  children: Node,
}

export default ({ children }: Props) => {
  const { hovered, targetProps } = useHover()
  return (
    <div
      {...targetProps}
      style={{
        transform: hovered ? 'translateX(10px)' : 'translateX(0)',
        transition: 'all .25s ease-in-out',
      }}
    >
      {children}
    </div>
  )
}
